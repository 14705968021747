import React from 'react';
import styled from 'styled-components';
import * as Antd from 'antd';
import * as AppActions from '../../AppActions';
import qs from 'query-string';

function OrderResult(props) {
  const params = qs.parse(props.location?.search);

  return (
    <Wrapper>
      <div className="content">
        <img src="/images/bakey.png" alt="bakey" />
        <h2>成功創建訂單</h2>
        <p>您現在可返回LINE</p>
        <div className="or">
          <div>或</div>
        </div>
        <Antd.Button
          size="large"
          type="primary"
          onClick={() => {
            if (params.store && params.order_id) {
              AppActions.navigate(
                `/store/profile/orders/?action=detail&store=${params.store}&order_id=${params.order_id}`,
              );
            }
          }}>
          查看訂單詳情
        </Antd.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #eee;
  padding: 20px;

  & > .content {
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    margin: 60px auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
      width: 200px;
      height: 200px;
      object-fit: center;
      margin: 20px;
    }

    & > h2 {
      font-size: 2rem;
      color: green;
      margin-bottom: 20px;
    }

    & > p {
      font-size: 1.5rem;
      color: #ccc;
    }

    & > div.or {
      align-self: stretch;
      display: flex;
      align-items: center;
      margin: 10px 0px;

      & > div {
        margin: 0px 10px;
        color: #aaa;
      }

      &::before {
        content: '';
        height: 1px;
        flex: 1;
        background-color: #aaa;
      }

      &::after {
        content: '';
        height: 1px;
        flex: 1;
        background-color: #aaa;
      }
    }
  }
`;

export default OrderResult;
